export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez vos achats de nourriture, d'épicerie, de livraison de colis et de pharmacie rapidement et facilement en ligne. Faites-vous livrer vos courses et commandez vos aliments préférés auprès des meilleurs fournisseurs."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez des répas et plus"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez les adresses proche de vous!"])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover local vendors that deliver to your doorstep"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir partenaire"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmentez vos ventes et accéder a de nouvelle opportunité avec livrado"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez Partenaire"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nous offrons un accès facile aux solutions numériques pour les entreprises et les restaurants locaux."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez Partenaire maintenant"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleurs les restaus proche de vous! Faite vous livrer vos plat préfère et découvre de nouveau restaurant"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapidité et satisfaction garantie"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver tout ce qu'il vous faut et bien plus encore supermarché, magasin, pharmacie"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger l'application LIVRADO pour une meilleur expérience!"])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir livreur"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez notre flotte"])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez livreur"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez dans l'application!"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger l'application pour profiter  d'offres exclusives"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrado Mali"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes & Conditions"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunités"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir livreur de"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir partenaire de"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer mon compte"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher un fournisseur"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOUS DROITS RÉSERVÉ"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies pour améliorer votre expérience sur notre site Web. En naviguant sur ce site, vous acceptez l'utilisation des cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure vente"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus récents"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populaires"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des produits"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche pour"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé pour"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir Tous"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs à proximité"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour Vous"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez les meilleurs Fournisseurs près de vous"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons tous vos fournisseurs préférés ici"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveautés"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveauté"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes flash"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente flash"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du jour"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fournisseur"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un fichier"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services populaires"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mieux notés"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rupture de stock"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure vente"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure vente"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandé pour vous"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits du fournisseur"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les options pour les ajouter au produit"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix total du produit"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide. Commencez à acheter maintenant."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Option disponible"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé de la commande"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sous-total n'inclut pas les frais de livraison"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Articles"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-Total"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rémise"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous un compte?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un code de réduction"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez une réduction"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire des achats avec le cryptage SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour un paiement plus fluide"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer l'achat"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou Mot de passe incorrect"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte ?"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ?"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue à Livrado Mali!"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En créant un compte, vous acceptez notre politique de confidentialité et nos conditions générales"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le Mot de passe"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez ici dans votre épicerie préférée, des différentes catégories"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande à emporter"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer si vous emporter la commande chez le fournisseur"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner l'adresse de livraison"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modes de paiement"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer commande"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une Adresse"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande sera prête dans"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Taxi"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Vehicle"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez une livraison de colis aujourd'hui"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui paie la commande ?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expediteur"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez des colis à vos amis et à votre famille."])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire et paramètres du Colis"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire du colis"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du Colis"])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre votre colis"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez maintenant"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières commandes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des colis"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre service de livraison à la demande"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout effacer"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de colis"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un type de colis"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fournisseur"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de livraison"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter l'Adresse de départ et de Destination ici"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de départ"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de destination"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des arrêts"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de colis"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un fournisseur"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le destinataire"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En confirmant j'accepte que cette commande ne contient pas d'articles illégaux/restreints, si des articles illégaux/restreints sont trouvés par "])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaire, ils peuvent le signaler aux autorités chargées de l'application de la loi. Termes et conditions d'application."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du colis"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tous les paramètres requis du colis ici"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de livraison"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais liés à la taille du colis"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du destinataire est requis"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone du destinataire est requis"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment souhaitez-vous recevoir votre commande ?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer maintenant"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nous acceptons les modes de paiement suivantes"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourboire du livreur"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer la commande"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de commandes"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Favoris"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien Mot de passe"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le Mot de passe"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le Mot de passe"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le Profile"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucune adresse de livraison"])}
  }
}