<template >
    <div class="" v-if="vendors">
        <div class="relative">
            <div class="container mx-auto md:max-w-full" :style="{ 'background-color': settings.colors.primaryColor }"
                v-if="settings">
                <div>
                    <img src="/img/1.png" class="h-auto w-full object-cover object-bottom" alt="" style="max-height: 60vh;">
                </div>
            </div>
            <div class="absolute top-12/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-10">
                <HomeBanner />
            </div>
        </div>

        <div class="container py-8 px-4 mx-auto max-w-full" v-if="settings"><!--Type vendors-->
            <h1
                class="font-bold text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl text-center pb-10 pt-20">
                Faites livrer tout ce que vous voulez</h1>
            <div class="flex flex-wrap justify-center gap-6">
                <router-link :to="{ name: 'VendorHome', params: { id: vendor.id, slug: sanitizeTitle(vendor.slug) } }"
                    class="" v-for="vendor in vendors" :key="vendor.id">
                    <div class="w-full p-2 rounded-full shadow-md" :style="{ background: vendor.color }">
                        <div class="mx-2">
                            <h1
                                class="text-center text-sm  sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-bold whitespace-nowrap">
                                {{ vendor.name }}</h1>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div>
            <!-- <svg viewBox="0 0 500 150" preserveAspectRatio="none" height="100" width="100%">
                <path d="M-1.69,119.91 C103.83,-99.16 160.26,321.22 501.12,14.31 L500.56,154.45 L0.00,150.00 Z"
                    :style="{ 'fill': settings.colors.primaryColor, 'stroke': none }"></path>
            </svg> -->
            <div class="container mx-auto md:max-w-full bg-black p-4" id="services" v-if="settings">
                <p
                    class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-semibold text-center text-white">
                    Découvrez les meilleurs établissements de BAMAKO
                </p>
                <!-- ////////////////////////////////////////////////////////////////////////////// -->

                <div v-if="nearbyvendors">
                    <div class="grid gap-4 text-center md:grid-cols-4 lg:grid-cols-4 grid-cols-4 " v-if="settings">
                        <router-link
                            :to="{ name: 'DefaultVendor', params: { id: nvendor.id, slug: sanitizeTitle(nvendor.name) } }"
                            class="flex flex-row" v-for="nvendor in nearbyvendors.slice(0, 4)" :key="nvendor.id">
                            <div class="p-2 sm:p-4 md:p-7 lg:p-10 xl:p-15 2xl:p-24">
                                <img v-if="nvendor" :src="nvendor.logo" class="object-fill mx-auto rounded-full  md:flex"
                                    :alt="nvendor.name">
                            </div>

                        </router-link>

                    </div>

                    <div class="grid gap-3 text-center md:grid-cols-3 lg:grid-cols-3 grid-cols-3 px-12 sm:px-12 md:px-20 lg:px-28 xl:px-40 2xl:px-44"
                        v-if="settings">
                        <router-link
                            :to="{ name: 'DefaultVendor', params: { id: nvendor.id, slug: sanitizeTitle(nvendor.name) } }"
                            class="flex flex-row" v-for="nvendor in nearbyvendors.slice(4, 7)" :key="nvendor.id">
                            <div class="p-2 sm:p-4 md:p-7 lg:p-10 xl:p-15 2xl:p-24">
                                <img v-if="nvendor" :src="nvendor.logo" class="object-fill mx-auto rounded-full  md:flex"
                                    :alt="nvendor.name">
                            </div>

                        </router-link>

                    </div>
                </div>
            </div>

            <!-- <svg class="" viewBox="0 0 500 150" preserveAspectRatio="none" height="100" width="100%">
                <path d="M-1.69,30.11 C147.29,162.34 162.52,-79.42 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
                    :style="{ 'fill': settings.colors.primaryColor, 'stroke': none, }"></path>
            </svg> -->
        </div>
        <div> <!--Garder Livraison dans votre poche-->
            <!-- <svg viewBox="0 0 500 150" preserveAspectRatio="none" height="100" width="100%">
                <path d="M-1.69,119.91 C103.83,-99.16 160.26,321.22 501.12,14.31 L500.56,154.45 L0.00,150.00 Z"
                    :style="{ 'fill': settings.colors.primaryColor, 'stroke': none }"></path>
            </svg> -->
            <div class="container mx-auto max-w-full" :style="{ 'background-color': settings.colors.primaryColor }"
                v-if="settings">
                <!-- <div class="grid grid-cols-1 md:gap-5 md:grid-cols-2"> -->

                <div class="pt-5 pb-3 px-15 w-full ">
                    <h1 class="text-xl font-bold tracking-wide text-white text-center"> Gardez Livrado dans votre
                        poche
                    </h1>
                    <h1 class="text-xl font-bold tracking-wide text-white pt-2 text-center"> Télécharger notre
                        application
                    </h1>

                </div>

                <div class="relative">
                    <img src="/img/1.png" class="m-auto pb-7 h-auto px-5 md:px-10 lg:px-14 xl:px-16 2xl:px20" alt="14">
                    <div
                        class="absolute bottom-5 md:bottom-4 lg:bottom-3 xl:bottom-1 2xl:bottom-0 left-3 md:left-7 lg:left-9 xl:left-9 2xl:left-9 mt-2">
                        <a :href="settings.strings.iosDownloadLink" target="_blank"><img src="/img/app-store.png"
                                class="h-10 md:h-16 lg:h-24 xl:h-36 2xl:h-44 m-0" /></a>
                        <a :href="settings.strings.androidDownloadLink" target="_blank"><img src="/img/play-store.png"
                                class="h-10 md:h-16 lg:h-24 xl:h-36 2xl:h-44 m-0" /></a>
                    </div>
                </div>
                <!-- </div> -->
            </div>

            <!-- <svg viewBox="0 0 500 150" preserveAspectRatio="none" height="100" width="100%" v-if="settings"
                :style="{ 'background-color': settings.colors.primaryColor }">
                <path d="M0.00,149.52 C256.49,-50.80 255.36,-47.84 499.43,150.50 L499.43,150.50 L0.00,149.52 Z"
                    :style="{ 'fill': '#000000', 'stroke': none, }">
                </path>
            </svg> -->
        </div>

        <p
            class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-semibold text-center text-black pt-10  pb-2">
            Rejoingnez nous
        </p>
        <!--Applications-->
        <div class="grid gap-2 text-center grid-cols-2 pb-10 my-0 lg:px-10 xl:px-20 2xl:px-30 " v-if="settings">
            <a :href="`${this.$store.state.baseUrl}register/vendor`" target="__blank">
                <div class="p-2 lg:w-full ">

                    <img class="rounded-lg mx-auto w-full h-full object-cover" src="/img/ym.jpg" />

                    <div>
                        <span
                            class=" mx-auto text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold whitespace-nowrap">Collaborer
                            avec nous </span>
                        <p class=" mx-auto text-xs sm:text-sm md:text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
                            Créer un compte professionnel</p>

                    </div>

                </div>
            </a>
            <a :href="`${this.$store.state.baseUrl}register/driver`" target="__blank">
                <div class="p-2 lg:w-full ">

                    <img class="rounded-lg mx-auto w-full h-full object-cover" src="/img/lv.jpg" />

                    <div>
                        <span
                            class=" mx-auto text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold whitespace-nowrap">Rouler
                            avec nous </span>
                        <p class=" mx-auto text-xs sm:text-sm md:text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
                            Créer un compte livreur</p>

                    </div>

                </div>
            </a>
        </div>
        <!-- </div> -->

    </div>
</template>
<!-- <script src="https://cdn.jsdelivr.net/npm/kute.js@2.1.2/dist/kute.min.js"></script> -->
<script>
import axios from "axios";
import HomeBanner from '@/components/Default/HomeBanner.vue'

export default {
    name: 'HomeView',

    components: {
        HomeBanner,
    },
    data() {
        return {
            vendors: null,
            settings: null,
            base_url: this.$store.state.baseUrl,
            search: null,
            types: null,
            //NESS
            nearbyvendors: null,
            AuthStr: 'Bearer ' + localStorage.getItem('authToken'),
        };
    },
    mounted() {
        var self = this;//Ness
        //


        this.$store.commit('loading', true)
        axios
            .get(this.base_url + "api/vendor/types")
            .then((response) => {
                this.vendors = response.data;
                // this.$store.commit('loading', false)
                //  console.log(this.vendors)
            })
            .catch((error) => console.log(error));

        ////////////////////////NESS
        if ("geolocation" in navigator) {
            // Le navigateur prend en charge la géolocalisation
            navigator.geolocation.getCurrentPosition(function (position) {
                // La latitude et la longitude sont disponibles dans l'objet position.coords
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;

                // Faites quelque chose avec les valeurs de latitude et de longitude
                axios
                    .get(self.base_url + 'api/vendors', {
                        params: {
                            page: '1',
                            latitude: latitude,
                            longitude: longitude,
                            // type: 'featured',
                        },
                        headers: { Authorization: self.AuthStr },
                    })
                    .then((response) => {
                        self.nearbyvendors = response.data.data.slice(0, 7);
                        self.$store.commit('loading', false)
                    })
                    .catch((error) => {
                        self.$store.commit('loading', false)
                        return console.log(error);
                    });
            }, function (error) {
                // self.$store.commit('loading', false)

                // En cas d'erreur lors de la récupération de la géolocalisation
                console.error("Erreur de géolocalisation : " + error.message);
                axios
                    .get(self.base_url + 'api/vendors', {
                        params: {
                            page: '1',
                            // type: 'featured',
                        },
                        headers: { Authorization: self.AuthStr },
                    })
                    .then((response) => {
                        self.nearbyvendors = response.data.data.slice(0, 7);
                        // console.log(self.nearbyvendors);
                        self.$store.commit('loading', false)
                    })
                    .catch((error) => {
                        self.$store.commit('loading', false)
                        return console.log(error);
                    });
            });
        } else {

            // Le navigateur ne prend pas en charge la géolocalisation
            // Faites quelque chose avec les valeurs de latitude et de longitude
            axios
                .get(self.base_url + 'api/vendors', {
                    params: {
                        page: '1',
                        // type: 'featured',
                    },
                    headers: { Authorization: self.AuthStr },
                })
                .then((response) => {
                    self.nearbyvendors = response.data.data.slice(0, 7);
                    // console.log(self.nearbyvendors);

                    self.$store.commit('loading', false)
                })
                .catch((error) => {
                    self.$store.commit('loading', false)
                    return console.log(error);
                });
        }
        ////////////////////////NESS

        this.$store.dispatch("fetchSettings", this.items).then(() => {
            this.settings = this.$store.state.settings;
            this.currency = this.settings.strings.currency;
            // console.log(this.settings)
        });
    },

    methods: {
        truncate(str, n) {
            return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
        },

        sanitizeTitle(title) {
            var slug = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
            // Letter "d"
            slug = slug.replace(/đ/gi, "d");
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, "");
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, "-");

            return slug;
        },
    }

}
</script>

  

