<template>
    <div class="md:max-w-xl  mx-auto " :style="{ 'background-color': settings.colors.primaryColor }" v-if="settings">
        <!-- <div class="flex justify-center items-center">
            <div class="w-15p h-64 bg-gray-500"></div>
        </div> -->
        <!-- <img :src="(`/img/${lang}.png`)"> -->

        <!-- <div class="container mx-auto max-w-7xl"> -->

        <!-- /////////////////////////////////////////////////////////////////////////// NESS -->
        <!-- <select v-model="$i18n.locale" class="w-16 px-3 m-2 text-black bg-transparent rounded-md">
                <option v-for="(lang, i) in $i18n.availableLocales" class="" :key="`Lang${i}`" :value="lang">
                    <span>{{ lang }}</span>
                </option>
            </select> -->
        <!-- /////////////////////////////////////////////////////////////////////////// NESS -->

        <div class="flex flex-col items-center justify-center py-5 ">


            <h1 class=" text-lg mx-3 sm:text-lg md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold text-center text-white "
                v-text="$t('landingpageHeader')"></h1>
            <!-- <p class="mt-4 text-sm font-light text-center text-white md:w-96"> {{ $t('landingpageSubHeader') }}</p> -->
            <div class="flex items-center justify-between mx-3 p-1 mt-5 bg-white rounded-full md:w-10/12" v-if="settings">
                <input class="w-full p-2 border-none rounded-full placeholder:font-light placeholder:text-xs"
                    v-model="search" type="text" placeholder="Chercher un produit ou un fournisseur" />
                <div @click="getSearchResult" class="flex items-center p-2 rounded-full cursor-pointer"
                    :style="{ 'background-color': settings.colors.primaryColor }">
                    <img src="/img/icons/search.svg" class="w-6" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import router from '@/router';
export default {
    name: 'HomeBanner',
    components: {

    },
    data() {
        return {
            search: null,
            base_url: this.$store.state.baseUrl,
            langs: ['en', 'es'],
            settings: null
        }
    },

    mounted() {
        axios
            .get(this.base_url + 'api/app/settings')
            .then((response) => {
                this.settings = response.data
            })
            .catch((error) => console.log(error));
    },

    methods: {
        getSearchResult() {
            router.push({ name: 'DefaultSearch', params: { slug: this.search } })
        }
    }
}
</script>